<nav class="navbar nav-pad navbar-expand-lg navbar-bg sticky-top" [ngClass]="{'border-bottom-thick-gr': isprod, 'border-bottom-thick-wh': !isprod}">

    <div class="collapse navbar-collapse main-nav-left" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto nav-center-fx">
            <li class="nav-item active toggle-icon mr-3" (click)="sidebarToggle()">
                <a class="nav-link btn"><i class="fa fa-bars" aria-hidden="true"></i></a>
            </li>
            <li class="nav-item active">
                <h5 class="mb-0 nav-title-font sg-font-regular">ESIM JCIUS {{customerName ?  '- ' : ''}}<span class="t-color" *ngIf="customerName">{{customerName}}</span></h5>
            </li>
        </ul>
        <ul class="nav navbar-nav align-items-center">
            <li class="nav-itme-container-margin">

                <div id="env-toggle" id="envTooltip" placement="bottom" tooltip ="{{ 'Environment' | translate }}" class="environment-toggle" [ngClass]="{'environment-toggle-active': isprod}">
                    <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': isprod}">
                        <p class="sg-font-small-regular">Prod</p>
                    </div>

                    <div>
                        <label class="switch">
                            <input type="checkbox" (click)="changeEnvironment()" [(ngModel)]="isprod" [disabled]="toggleDisable">
                            <span class="slider round" [ngClass]="{'cursor-not-allowed': toggleDisable}"></span>
                        </label>
                    </div>

                    <div class="environment-toggle-text-container" [ngClass]="{'environment-toggle-active-text': !isprod}">
                        <p class="sg-font-small-regular">PreProd</p>
                    </div>
                </div>

            </li>
            <li class="nav-itme-container-margin d-flex nav-lang-container">
                <div class="dropdown" style="background: var(--bg-inner-app);" >
                    <a class="lang-dropdown sg-font-small-regular" role="button" id="dropdownMenuLink-lang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span id="languageTootltip" placement="bottom" tooltip="{{'Language' | translate }}">{{selectedLanguage}}
                            <i class="fa fa-angle-down ml-1" style="color: #707070; top: 4px;right: -18px;"></i>
                        </span>
                    </a>

                    <div class="dropdown-menu lang-dropdown-option border-secondary" aria-labelledby="dropdownMenuLink-lang">
                        <a class="dropdown-item lang-dropdown-item sg-font-regular" [ngClass]="{'lang-dropdown-item-active': langSelected===lang.key}" *ngFor="let lang of languageList" (click)="langSelected=lang.key; changeLanguage();">{{lang.name}}</a>
                    </div>
                </div>
            </li>
            <li class="nav-item active nav-itme-container-margin">
                <div class="dropleft notification-container">
                    <a class="" style="position:relative;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <!-- <i class="fa fa-bell t-gray" style="font-size: 20px; margin-top: 10px;" (click)="badgeShow = false"> </i> -->
                        <img src="../assets/images/bell.png" style="width: 23px;" alt="" (click)="badgeShow = false;" id="notificationTooltip" placement="bottom" tooltip="{{'Notifications' | translate }}">
                        <div class="notification-badge" *ngIf="badgeShow">
                            <div class="notification-badge-inner"></div>
                        </div>
                    </a>

                    <div class="dropdown-menu border-light" aria-labelledby="dropdownMenuLink">
                        <div class="border-bottom-light d-flex align-items-center justify-content-between py-1 px-2">
                            <p class="mb-0 notification-head-font">{{ "Notifications" | translate}}</p>
                            <a class="small t-color" [routerLink]="['/notifications']">{{ "View All" | translate}}</a>
                        </div>
                        <div class="dropdown-item-container" *ngIf="notificationList && notificationList.length === 0">
                            <a class="dropdown-item border-bottom-light">
                                <div class="notification-container-body-inner">
                                    <div class="notification-container-date text-center">
                                        <p class="small mb-0">{{ "Notifications Not Found" | translate}}.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-item-container" *ngIf="notificationList && notificationList.length > 0">
                            <a class="dropdown-item border-bottom-light" *ngFor="let notification of notificationList">
                                <div class="notification-container-body-inner">
                                    <div class="notification-container-date text-right">
                                        <p class="small mb-0">{{ notification.created | date: "MMM d, y, hh:mm:ss a" }}</p>
                                    </div>
                                    <div class="notification-container-body-specs">
                                        <p class="mb-0">{{ notification.transMessage | translate | limitTo: 100}}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item nav-itme-container-margin d-flex align-items-center justify-content-center">
                <span class="mb-0 support-icon" id='supportTooltip' placement="bottom" tooltip="{{ 'Support' | translate}}">
                    <!-- <i class="fa fa-question-circle cursor-p"  style="font-size: 26px;"></i> -->
                    <a href="https://helpdesk.10ttech.com/" target="_blank">
                        <img src="../assets/images/information.png" class="cursor-p" style="width: 25px;" alt="">
                    </a>
                    
                </span>
            </li>
        </ul>
    </div>
    <div style="max-width: 310px;min-width: 175px;flex-shrink: 0;">
        <div class="dropleft show">
            <a class="nav-link pb-0 nav-profile d-flex align-items-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="nav-profile-image-container">
                    <img src="/assets/images/icons/profile-placeholder.png" class="nav-profile-image" id="profileTooltip" alt=""  placement="bottom" tooltip="{{'Profile' | translate }}">
                    <div class="nav-profile-badge"></div>
                </div>
                <div class="ml-2 nav-username-container" *ngIf="user && user.data">
                    <p class="mb-0 nav-username-head" title="{{user.data.displayName}}" >{{displayName | limitTo: 25}}</p>
                    <div class="nav-user-role-tooltip">
                        <span class="nav-username-subhead sg-font-small-regular">{{roleNameString | limitTo: 35}}</span>
                        <span class="t-color sg-font-regular" style="font-size: 12px;" *ngIf='roleNameTooltipCount'> +{{roleNameTooltipCount}}</span>
                        <div class="nav-user-rolename-tooltip" *ngIf='roleNameTooltipCount'>
                            <i class="fa fa-caret-up caret-icon"></i>
                            <div class="overflow-roles">
                                <p class="roleTooltip-margin sg-font-regular" *ngFor="let roleTooltip of roleNameTooltip.split(',')">{{roleTooltip}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <div class="dropdown-menu profile-dropdown sg-font-regular" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item cursor-p" [routerLink]="['/my-account']">{{ "My Account" | translate}}</a>
                <a class="dropdown-item cursor-p" [routerLink]="['/dashboard-refresh-time']">{{ "Auto Refresh Time" | translate}}</a>
                <a class="dropdown-item cursor-p" style="white-space: normal;" [routerLink]="['/email-setup']" *ngIf="(routePermission && routePermission.length > 0 && routePermission.indexOf('email-setup') !== -1) && ((currentCustomer && !currentCustomer.isRoot) || (!currentCustomer && rootCustomer && !rootCustomer.isRoot)) && (features && features.length > 0 && features[getMainFeatureIndex('email-setup')].display)">{{ "Email Setup" | translate}}</a>
                <a class="dropdown-item cursor-p" style="white-space: normal;" [routerLink]="['/theme']" *ngIf="routePermission && routePermission.length > 0 && routePermission.indexOf('theme') !== -1">{{ "Edit Theme" | translate}}</a>
                <a class="dropdown-item cursor-p" style="white-space: normal;" [routerLink]="['/login-theme']" *ngIf="routePermission && routePermission.length > 0 && routePermission.indexOf('login-theme') !== -1">{{ "Login Theme" | translate}}</a>
                <a class="dropdown-item cursor-p" style="white-space: normal;" *ngIf="langSelected != 'ru'" href="assets/api-doc/ESIM JCIUS User Guide v1.7 English.pdf" download>{{ "User Guide" | translate}}</a>
                <a class="dropdown-item cursor-p" style="white-space: normal;" *ngIf="langSelected === 'ru'" href="assets/api-doc/ESIM JCIUS User Guide Russian 1.4.pdf" download>{{ "User Guide" | translate}}</a>
                <a class="dropdown-item cursor-p" (click)="signout()">{{ "Logout" | translate}}</a>
            </div>
        </div>
    </div>
</nav>

<!-- welcome message modal -->
<div class="modal welcomeMessageParent" id="welcomeMessage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width:800px;" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center" style="border-bottom: none;">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <img src="assets/images/icons/handshake.png" class="welcomeImage" alt="">
            <div class="modal-body device-flex-body" style="padding: 35px;">
                <h4 class="text-center messageTitle">
                    {{ "Welcome to ESIM JCIUS" | translate}}
                </h4>
                <p class="text-center messageBody">
                    {{ "We are very pleased to have started this journey with you on a successful Proof of Concept and Production Readiness." | translate}}
                </p>
                <p class="text-center messageBodySec">
                    {{ "ESIM JCIUS is your go to tool for day-to-day eSIM Management and you may refer to User Guide for walkthrough of all our features. The document can be found under Profile icon. Thank you." | translate}}
                </p>
            </div>
        </div>
    </div>
</div>