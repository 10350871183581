import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { ColorPickerModule } from 'ngx-color-picker';
import { ResizableModule } from 'angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from './shared/component/component.module';
import { ProfileModule } from './profile/profile.module';
import { SigninComponent } from './auth/signin/signin.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './auth//.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { filterReducer } from './shared/stores/filter/filter.reducer';
import { ManageGroupProfileModule } from './manage-group-profile/manage-group-profile.module';
import { ProfileTroubleshootingModule } from './profile-troubleshooting/profile-troubleshooting.module';
import { userReducer } from './shared/stores/user/user.reducer';
import { ApiTreeModule } from './api-tree/api-tree.module';
import { UiCustomizationComponent } from './ui-customization/ui-customization.component';
import { themeReducer } from './shared/stores/theme/theme.reducer';
import { logoReducer } from './shared/stores/logo/logo.reducer';
import { ReportModule } from './report/report.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { NotificationComponent } from './notification/notification.component';
import { SvgEditorComponent } from './svg-editor/svg-editor.component';
import { DrawComponentModule } from './svg-editor/components/component.module';
import { GovernanceReportModule } from './governance-report/governance-report.module';
import { SharedModule } from './shared/shared.module';
import { AccessManagementModule } from './access-management/access-management.module';
import { SupportComponent } from './support/support.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { groupReducer } from './shared/stores/group/group.reducer';
import { CustomerComponent } from './customer/customer.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { ChartsModule } from 'ng2-charts';
import { SmartjacSigninComponent } from './auth/smartjac-signin/smartjac-signin.component';
import { SmartjacResetPasswordComponent } from './auth/smartjac-reset-password/smartjac-reset-password.component';
import { SmartjacForgotPasswordComponent } from './auth/smartjac-/smartjac-.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { manageGroupQrFilterReducer } from './shared/stores/manage-group-qr-filter/filter.reducer';
import { hierarchyReducer } from './shared/stores/hierarchy-tree/hierarchy.reducer';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';
import { ProfileTypeComponent } from './dynamic-dashboard/profile-type/profile-type.component';
import { TotalDownloadComponent } from './dynamic-dashboard/total-download/total-download.component';
import { DeviceAnalyticsComponent } from './dynamic-dashboard/device-analytics/device-analytics.component';
import { DownloadForecastGraphComponent } from './dynamic-dashboard/download-forecast-graph/download-forecast-graph.component';
import { DownloadTrendGraphComponent } from './dynamic-dashboard/download-trend-graph/download-trend-graph.component';
import { GridsterModule } from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';
import { MatTableModule } from '@angular/material/table';
import { DashboardReducer } from './shared/stores/dynamic-dashboard-sidebar/dashboard.reducer';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoginScreenCustomiztionComponent } from './login-screen-customiztion/login-screen-customiztion.component';
import { DashboardRefreshTimeComponent } from './dashboard-refresh-time/dashboard-refresh-time.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    UiCustomizationComponent,
    PageNotFoundComponent,
    MyAccountComponent,
    NotificationComponent,
    SvgEditorComponent,
    SupportComponent,
    DashboardComponent,
    CustomerComponent,
    DashboardUserComponent,
    SmartjacSigninComponent,
    SmartjacResetPasswordComponent,
    SmartjacForgotPasswordComponent,
    EmailConfigurationComponent,
    DynamicDashboardComponent,
    ProfileTypeComponent,
    TotalDownloadComponent,
    DeviceAnalyticsComponent,
    DownloadForecastGraphComponent,
    DownloadTrendGraphComponent,
    LoginScreenCustomiztionComponent,
    DashboardRefreshTimeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentModule,
    ProfileModule,
    ManageGroupProfileModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatCheckboxModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    StoreModule.forRoot({
      filter: filterReducer,
      user: userReducer,
      theme: themeReducer,
      logo: logoReducer,
      group: groupReducer,
      manageGroupQrFilter: manageGroupQrFilterReducer,
      hierarchy: hierarchyReducer,
      dashboard: DashboardReducer
    }),
    ResizableModule,
    ProfileTroubleshootingModule,
    ApiTreeModule,
    ColorPickerModule,
    ReportModule,
    ResizableModule,
    DrawComponentModule,
    ColorPickerModule,
    ProfileTroubleshootingModule,
    GovernanceReportModule,
    AccessManagementModule,
    NgMultiSelectDropDownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule,
    NgxIntlTelInputModule,
    ChartsModule,
    GridsterModule,
    DynamicModule,
    MatTableModule,
    TooltipModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
