<div class="smartjac-main">
	<section class="new-auth-section">
		<section class="bg-onboarding">
			<div class="signin-page" data-theme='dark'>
				<div class="signin-inner" data-theme='dark'>
					<div class="row">
						<div class="col-lg-6 d-flex flex-column left-signin-inner">
							<!-- <div>
								<img class="auth-trance-logo" src="" alt="">
							</div> -->
							<div class="logo mt-auto mx-auto pb-5">
								<img class="img-logo" src="../../../assets/images/smartjac/logo/SquareLogo.png">
							</div>
							<!-- <div class="ml-3 mb-3 t-powered-parent">
								<span class="mr-2">{{ "Powered by" | translate}}</span> <img class="t-powered-logo" src="images/jcius.png" alt="">
							</div> -->
						</div>
						<div class="col-lg-6 right-signin-inner">
							<!-- <div class="d-flex justify-content-end mt-3 mr-3 main-lang-dropdown">
								<select class="px-2 t-input auth-lang-dropdown" style="border-radius: 30px; height: 35px; outline: none;" [(ngModel)]="langSelected" (ngModelChange)="changeLanguage()">
									<option value="en">ENG</option>
									<option value="ru">RU</option>
									<option value="mr">MR</option>
								</select>
								<i class="fa fa-angle-down "></i>
							</div> -->
							<div>
								<form [formGroup]="forgotPassForm" (ngSubmit)="onForgotPass()">
									<div class="createaccount-form">
	
										<div class="form-group mb-4">
											<label for="exampleInputEmail1" class="mb-0">{{ "Email address" | translate}}</label>
											<input type="email" [ngClass]="{'submitted': submitted}" class="form-control onboarding-form-input" formControlName="email" [placeholder]="'Email' | translate">
											<div>
												<p class="text-danger text-center" *ngIf="err">{{ "The username or password you have entered is incorrect" | translate}}.</p>
												<span class="help-block ng-invalid text-danger" *ngIf="(!f.email.valid && !f.email.pristine) || (f.email.errors && submitted)">
											  <span class="small" *ngIf="f.email.errors.required">
												{{ "Email is required" | translate}}!
											  </span>
												</span>
												<span class="help-block ng-invalid text-danger" *ngIf="f.email.errors && submitted">
											  <span class="small" *ngIf="f.email.errors.email">
												{{ "Enter valid email" | translate}}!
											  </span>
												</span>
											</div>
										</div>
	
										<div class="form-group">
											<button class="btn btn-block signin-inner-btn outline" type="submit">{{ "Submit Now" | translate}}</button>
											<button class="btn btn-block signin-inner-cancel-btn mt-3 outline" routerLink="/signin" type="submit">{{ "Cancel" | translate}}</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
	
		</section>
	</section>
</div>