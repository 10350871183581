<div class="inner-main-profile" *ngIf="features && features[getFeatureIndex('profile_group')].view">
    <div class="result-row">
        <div class="result-table">
            <div class="face-container-inner-bg">
                <div class="profile-manage-row result-not-found-row">
                    <h4 class="result-not-found-row-heading">{{'Manage Group QR' | translate}}</h4>
                    <button class="btn t-bgcolor outline" *ngIf="features && features[getFeatureIndex('profile_group')].edit" [disabled]="!show" (click)="spinner = false;openGrpProfileModal();editOn=false;submitted=false;"> <i class="fa fa-plus mr-2" aria-hidden="true"></i>{{'Create Group QR' | translate}}</button>
                </div>
                <div class="report-head-section">
                    <ul class="device-flex nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item" (click)="tabId='active';getGroupProfiles();">
                            <a class="nav-link" [ngClass]="{'active': tabId === 'active'}" id="pills-active-tab" data-toggle="pill" href="#pills-active" role="tab" aria-controls="pills-active" aria-selected="true">{{'Active' | translate}}</a>
                        </li>
                        <li class="nav-item" (click)="tabId='inactive';getGroupProfiles();">
                            <a class="nav-link device-flex-title" [ngClass]="{'active': tabId === 'inactive'}" id="pills-inactive-tab" data-toggle="pill" href="#pills-inactive" role="tab" aria-controls="pills-inactive" aria-selected="false">{{'Inactive' | translate}}</a>
                        </li>
                        <li class="nav-item" (click)="tabId='deleted';getGroupProfiles();">
                            <a class="nav-link device-flex-title" [ngClass]="{'active': tabId === 'deleted'}" id="pills-deleted-tab" data-toggle="pill" href="#pills-deleted" role="tab" aria-controls="pills-deleted" aria-selected="false">{{'Deleted' | translate}}</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content device-flex-body" id="pills-tabContent">
                    <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'active'}" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab">
                        <div class="t-grid-table-new">
                            <div class="t-grid-table-wrapper t-head-bg t-head-group-qr" [ngClass]="{'grid-head-padding': (grpProfileList && grpProfileList.length >= 10)}">
                                <div class="t-box a">{{'Group QR Name' | translate}}</div>
                                <div class="t-box b">{{'Profile Type' | translate}}</div>
                                <div class="t-box b">{{'Group Type' | translate}}</div>
                                <div class="t-box b">{{'Group Activation Code' | translate}}</div>
                                <div class="t-box b text-center" *ngIf="features && features[getFeatureIndex('group_qr_code')].view">{{'QR Code' | translate}}</div>
                                <div class="t-box b" *ngIf="features && (features[getFeatureIndex('profile_group')].edit || features[getFeatureIndex('profile_group')].delete || features[getFeatureIndex('profile_group')].view)"></div>
                            </div>
                            <div class="loader-flex-center" *ngIf="grpProfileSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="grpProfileList && grpProfileList.length <= 0 && !grpProfileSpinner">
                                <div class="text-center mt-2 mb-2">
                                    <div class="text-center">{{'No Data Found' | translate}}.</div>
                                </div>
                            </div>
                            <div class="t-body-overflow" *ngIf="!grpProfileSpinner">
                                <div class="t-grid-table-wrapper t-body-bg" *ngFor="let profile of grpProfileList;let i = index;">
                                    <div class="t-box d t-font-light">{{profile.groupName}}</div>
                                    <div class="t-box d t-font-light">{{profile.profileType}}</div>
                                    <div class="t-box d t-font-light" [ng]>{{profile.groupType}}</div>
                                    <div class="t-box d t-font-light">{{profile.groupMatchingId}}</div>
                                    <div class="t-box d t-font-light text-center" *ngIf="features && features[getFeatureIndex('group_qr_code')].view">
                                        <i class="fa fa-qrcode fa-lg c-color cursor-p" data-toggle="modal" style="transform: translateY(1px);" data-target="#qrCodeModal" (click)="qrOption = 'QR Code';logoId='';qrCodeIndex=i;qrCode='';getQrcode(profile.groupMatchingId);"></i>
                                    </div>
                                    <div class="t-box d t-font-light edit-delete" *ngIf="features && (features[getFeatureIndex('profile_group')].edit || features[getFeatureIndex('profile_group')].delete || features[getFeatureIndex('profile_group')].view)">
                                        <i class="fa fa-pencil-square-o t-color cursor-p mr-3" style="font-size: 1.33333333em;transform: translateY(1.5px);" tooltip="{{'Edit' | translate}}" aria-hidden="true" data-toggle="modal" data-target="#createEditGroupProfile" (click)="editOn = true;getSingleGrpProfile(i);submitted=false;" *ngIf="profile.groupStatus !== 'deleted' && features && features[getFeatureIndex('profile_group')].edit"></i>
                                        <i class="fa fa-pencil-square-o t-color-muted mr-3" style="font-size: 1.33333333em;transform: translateY(1.5px);" tooltip="{{'Edit' | translate}}" aria-hidden="true" *ngIf="profile.groupStatus === 'deleted' && features && features[getFeatureIndex('profile_group')].edit"></i>
                                        <i class="fa fa-trash t-color cursor-p mr-3" style="font-size: 1.33333333em;" tooltip="{{'Delete' | translate}}" data-toggle="modal" data-target="#deletGrpProfile" aria-hidden="true" (click)="profileIndex = i" *ngIf="profile.groupStatus !== 'deleted' && features && features[getFeatureIndex('profile_group')].delete"></i>
                                        <i class="fa fa-trash t-color-muted" style="font-size: 1.33333333em;" tooltip="{{'Delete' | translate}}" aria-hidden="true" *ngIf="profile.groupStatus === 'deleted' && features && features[getFeatureIndex('profile_group')].delete"></i>
                                        <i class="fa fa-eye t-color cursor-p mr-3" style="font-size: 1.33333333em;transform: translateY(1.5px);" tooltip="{{'View' | translate}}" aria-hidden="true" (click)="viewGroupProfile(i);" *ngIf="profile.groupStatus !== 'deleted' && profile.groupType === 'private' && features && features[getFeatureIndex('profile_group')].view"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'inactive'}" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab">
                        <div class="t-grid-table-new">
                            <div class="t-grid-table-wrapper t-head-bg t-head-group-qr" [ngClass]="{'grid-head-padding': (grpProfileList && grpProfileList.length >= 10)}">
                                <div class="t-box a">{{'Group QR Name' | translate}}</div>
                                <div class="t-box b">{{'Profile Type' | translate}}</div>
                                <div class="t-box b">{{'Group Type' | translate}}</div>
                                <div class="t-box b">{{'Group Activation Code' | translate}}</div>
                                <div class="t-box b text-center" *ngIf="features && features[getFeatureIndex('group_qr_code')].view">{{'QR Code' | translate}}</div>
                                <div class="t-box b" *ngIf="features && (features[getFeatureIndex('profile_group')].edit || features[getFeatureIndex('profile_group')].delete)"></div>
                            </div>
                            <div class="loader-flex-center" *ngIf="grpProfileSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="grpProfileList && grpProfileList.length <= 0 && !grpProfileSpinner">
                                <div class="text-center mt-2 mb-2">
                                    <div class="text-center">{{'No Data Found' | translate}}.</div>
                                </div>
                            </div>
                            <div class="t-body-overflow" *ngIf="!grpProfileSpinner">
                                <div class="t-grid-table-wrapper t-body-bg" *ngFor="let profile of grpProfileList;let i = index;">
                                    <div class="t-box d t-font-light">{{profile.groupName}}</div>
                                    <div class="t-box d t-font-light">{{profile.profileType}}</div>
                                    <div class="t-box d t-font-light">{{profile.groupType}}</div>
                                    <div class="t-box d t-font-light">{{profile.groupMatchingId}}</div>
                                    <div class="t-box d t-font-light text-center" *ngIf="features && features[getFeatureIndex('group_qr_code')].view">
                                        <i class="fa fa-qrcode fa-lg c-color cursor-p" style="transform: translateY(1px);" data-toggle="modal" data-target="#qrCodeModal" (click)="qrOption = 'QR Code';logoId='';qrCodeIndex=i;qrCode='';getQrcode(profile.groupMatchingId);"></i>
                                    </div>
                                    <div class="t-box d t-font-light edit-delete" *ngIf="features && (features[getFeatureIndex('profile_group')].edit || features[getFeatureIndex('profile_group')].delete)">
                                        <i class="fa fa-pencil-square-o t-color cursor-p mr-3" style="font-size: 1.33333333em; transform: translateY(1.5px);"  tooltip="{{'Edit' | translate}}" aria-hidden="true" data-toggle="modal" data-target="#createEditGroupProfile" (click)="editOn = true;getSingleGrpProfile(i);submitted=false;" *ngIf="profile.groupStatus !== 'deleted' && features && features[getFeatureIndex('profile_group')].edit"></i>
                                        <i class="fa fa-pencil-square-o t-color-muted mr-3" style="font-size: 1.33333333em; transform: translateY(1.5px);" tooltip="{{'Edit' | translate}}" aria-hidden="true" *ngIf="profile.groupStatus === 'deleted' && features && features[getFeatureIndex('profile_group')].edit"></i>
                                        <i class="fa fa-trash t-color cursor-p mr-3" style="font-size: 1.33333333em;" tooltip="{{'Delete' | translate}}" data-toggle="modal" data-target="#deletGrpProfile" aria-hidden="true" (click)="profileIndex = i" *ngIf="profile.groupStatus !== 'deleted' && features && features[getFeatureIndex('profile_group')].delete"></i>
                                        <i class="fa fa-trash t-color-muted" style="font-size: 1.33333333em;" tooltip="{{'Delete' | translate}}" aria-hidden="true" *ngIf="profile.groupStatus === 'deleted' && features && features[getFeatureIndex('profile_group')].delete"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show" [ngClass]="{'active': tabId === 'deleted'}" id="pills-deleted" role="tabpanel" aria-labelledby="pills-deleted-tab">
                        <div class="t-grid-table-new">
                            <div class="t-grid-table-wrapper-deleted t-head-bg t-head-group-qr" [ngClass]="{'grid-head-padding': (grpProfileList && grpProfileList.length >= 10)}">
                                <div class="t-box a">{{'Group QR Name' | translate}}</div>
                                <div class="t-box b">{{'Profile Type' | translate}}</div>
                                <div class="t-box b">{{'Group Type' | translate}}</div>
                                <div class="t-box b">{{'Group Activation Code' | translate}}</div>
                            </div>
                            <div class="loader-flex-center" *ngIf="grpProfileSpinner">
                                <div class="loader quantum-spinner m-auto"></div>
                            </div>
                            <div *ngIf="grpProfileList && grpProfileList.length <= 0 && !grpProfileSpinner">
                                <div class="text-center mt-2 mb-2">
                                    <div class="text-center">{{'No Data Found' | translate}}.</div>
                                </div>
                            </div>
                            <div class="t-body-overflow" *ngIf="!grpProfileSpinner">
                                <div class="t-grid-table-wrapper-deleted t-body-bg" *ngFor="let profile of grpProfileList;let i = index;">
                                    <div class="t-box d t-font-light">{{profile.groupName}}</div>
                                    <div class="t-box d t-font-light">{{profile.profileType}}</div>
                                    <div class="t-box d t-font-light">{{profile.groupType}}</div>
                                    <div class="t-box d t-font-light">{{profile.groupMatchingId}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createEditGroupProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{editOn ? ('Edit Group QR' | translate): ('Create Group QR' | translate)}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" (click)="editOn = false;closeModal();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="loader-flex-center" *ngIf="spinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
                <form [formGroup]="grpProfileForm" (ngSubmit)="editOn ? editGrpProfile() : onSubmit()" *ngIf="!spinner">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <mat-form-field appearance="outline" style="width: 100%;" *ngIf="!profileType">
                                    <mat-label style="transform: translateY(-30px);"> {{'Select Profile Type' | translate}}</mat-label>
                                    <mat-select formControlName="profileType" [disableControl]="editOn">
                                        <mat-option *ngFor="let profile of profileTypes" [value]="profile.name">{{profile.name}}</mat-option>
                                    </mat-select>
                                    <mat-hint>
                                        <span class="help-block ng-invalid text-danger" *ngIf="submitted && ((!f.profileType.valid && !f.profileType.pristine) || (f.profileType.errors))">
                                            <span *ngIf="f.profileType.errors && f.profileType.errors.required">
                                                {{'Profile Type is required' | translate}}!
                                            </span>
                                        </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <label class="t-label" for="exampleInputEmail1">{{'Group QR Name' | translate}}</label>
                                <div class="d-flex align-item-center">
                                    <span class="mr-2">10T_</span><input type="text" class="form-control t-input" formControlName="groupName" aria-describedby="emailHelp" [disableControl]="editOn" *ngIf="!groupName"><span class="ml-2">_T01</span>
                                </div>
                                <span *ngIf="f.groupName.value" style="word-break: break-all;">10T_{{f.groupName.value}}_T01</span>
                                <span class="help-block ng-invalid text-danger" *ngIf="submitted && ((!f.groupName.valid && !f.groupName.pristine) || (f.groupName.errors))">
                                  <span class="small" *ngIf="f.groupName.errors && f.groupName.errors.required">
                                    {{'Group QR Name is required!' | translate}}
                                  </span>
                                </span>
                            </div>

                            <div class="form-group grp-profile-status">
                                <mat-form-field appearance="outline" style="width: 100%;">
                                    <mat-label>{{'Select Status' | translate}}</mat-label>
                                    <mat-select formControlName="status">
                                        <mat-option value="active">Active</mat-option>
                                        <mat-option value="inactive">Inactive</mat-option>
                                    </mat-select>
                                    <mat-hint>
                                        <span class="help-block ng-invalid text-danger" *ngIf="(!f.status.valid && !f.status.pristine) || (f.status.errors && submitted)">
                                            <span *ngIf="f.status.errors && f.status.errors.required">
                                                {{'Status is required' | translate}}!
                                            </span>
                                        </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <mat-form-field appearance="outline" style="width: 100%;" *ngIf="!groupType">
                                    <mat-label>{{'Select Group Type' | translate}}</mat-label>
                                    <mat-select formControlName="groupType" [disableControl]="editOn">
                                        <mat-option value="public">Public</mat-option>
                                        <mat-option value="private">Private</mat-option>
                                    </mat-select>
                                    <mat-hint>
                                        <span class="help-block ng-invalid text-danger" *ngIf="(!f.groupType.valid && !f.groupType.pristine) || (f.groupType.errors && submitted)">
                                            <span *ngIf="f.groupType.errors && f.groupType.errors.required">
                                                {{'Group Type is required' | translate}}!
                                            </span>
                                        </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <label class="t-label" for="downloadLimit" *ngIf="flg">{{'Download Limit' | translate}}</label>
                                <input type="number" class="form-control t-input" formControlName="downloadLimit" aria-describedby="emailHelp" *ngIf="flg">
                                <span class="help-block ng-invalid text-danger" *ngIf="(f.groupType.value === 'public') && (!f.downloadLimit.valid && !f.downloadLimit.pristine) || (f.groupType.value === 'public') && (f.downloadLimit.errors && submitted)">
                                    <span class="small" *ngIf="f.downloadLimit.errors && f.downloadLimit.errors.required">
                                        {{'Download limit required' | translate}}!
                                    </span>
                                    <span class="small" *ngIf="f.downloadLimit.errors && f.downloadLimit.errors.min">
                                        {{'Download limit minimum is 100' | translate}}!
                                    </span>
                                </span>
                            </div>

                            <div class="form-group">
                                <label class="t-label" for="count" *ngIf="!flg">{{'Count' | translate}}</label>
                                <input type="number" class="form-control t-input" formControlName="count" aria-describedby="emailHelp" [disableControl]="editOn" *ngIf="!flg">
                                <span class="help-block ng-invalid text-danger" *ngIf="(f.groupType.value === 'private') && ((!f.count.valid && !f.count.pristine) || (f.count.errors && submitted))">
                                  <span class="small" *ngIf="f.count.errors && f.count.errors.required">
                                    {{'Count is required' | translate}}!
                                  </span>
                                  <span class="small" *ngIf="f.count.errors && f.count.errors.max">
                                    {{'Count is up to' | translate}} {{f.count.errors.max.max}}!
                                  </span>
                                  <span class="small" *ngIf="f.count.errors && f.count.errors.min">
                                    {{'Count minimum is' | translate}} {{f.count.errors.min.min}}!
                                  </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="t-label" for="count">{{'Description' | translate}}</label>
                                <textarea class="form-control t-input" formControlName="description" rows="2"></textarea>
                                <span class="help-block ng-invalid text-danger" *ngIf="submitted && ((!f.description.valid && !f.description.pristine) || (f.description.errors))">
                                    <span class="small" *ngIf="f.description.errors && f.description.errors.required">
                                        {{'Description is required' | translate}}!
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" [disabled]="isSubmitButtonDisabled()"> {{'Submit' | translate}}</button>
                    <div class="btn-spinner" *ngIf="btnSpinner">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deletGrpProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delete Confirmation' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center p-4">
                <img src="assets/images/icons/delete.png" style="width: 50px; margin-bottom: 30px" alt="">
                <p>{{'Are you sure you want to delete' | translate}} ?</p>
                <button class="btn t-bgcolor btn-block my-3 outline" *ngIf="!btnSpinner" (click)="deleteGrpProfile()"> {{'Delete Now' | translate}}</button>
                <div class="btn-spinner" *ngIf="btnSpinner">
                    <div class="loader quantum-spinner m-auto"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="qrCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal" style="width: 1000px !important;">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{'Generate Group QR Code' | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closeDeleteCustomer" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3 device-flex-body" style="min-height: 306px;display: flex;flex-direction: column;justify-content: space-between;">
                <div class="mt-2">
                    <mat-radio-group aria-labelledby="example-radio-group-label" style="justify-content: space-between; padding: 0px 5px;" class="example-radio-group d-flex" [(ngModel)]="qrOption">
                        <mat-radio-button class="example-radio-button mr-2" [value]="qrCodeOptions[0]">
                            {{qrCodeOptions[0] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-2" [value]="qrCodeOptions[1]" *ngIf="features && features[getFeatureIndex('groupqr_with_logo')].edit">
                            {{qrCodeOptions[1] | translate}}
                        </mat-radio-button>
                        <mat-radio-button class="example-radio-button mr-2" [value]="qrCodeOptions[2]" *ngIf="features && features[getFeatureIndex('groupqr_with_campaign')].edit">
                            {{qrCodeOptions[2] | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="text-center mt-4 mb-2" *ngIf="qrOption==='QR Code'">
                    <img src="{{qrCode}}" alt="" *ngIf="qrCode">
                    <div class="loader-flex-center" *ngIf="!qrCode">
                        <div class="loader quantum-spinner m-auto"></div>
                    </div>
                </div>

                <div class="d-flex mt-4 justify-content-around align-items-center" *ngIf="qrOption==='QR Code With Logo'" style="justify-content: center !important;">
                    <div class="pl-1 t-color cursor-p" style="margin-right: 95px;" (click)="getQrLogos();"><span class="mr-2">{{"Manage Logo" | translate}}</span><span><i class="fa fa-plus"></i></span></div>
                    <div class="text-font-radio-top ml-3"><img class="radio-qr-img" style="width: 85px;" src="{{qrCodeWithLogoImg}}" alt=""></div>
                </div>

                <div class="text-center mt-4" *ngIf="qrOption==='QR Code With Campaign'">
                    <div class="d-flex">
                        <div class="ml-2">
                            <mat-form-field appearance="outline" [ngStyle]="{'width': selectionWidth}">
                                <mat-label>{{"Select Name" | translate}}</mat-label>
                                <mat-select [(ngModel)]="selectedTempateId" [ngModelOptions]="{standalone: true}" (ngModelChange)="getSingleTemplate();">
                                    <mat-option *ngFor="let template of templateList" [value]="template._id">
                                        {{template.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="mr-2 ml-auto">
                            <mat-form-field appearance="outline" [ngStyle]="{'width': selectionWidth}">
                                <mat-label>{{"Select Format" | translate}}</mat-label>
                                <mat-select [(ngModel)]="selectedCampaignFormat" [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let format of campaignFormat" [value]="format">
                                        {{format}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="text-font-radio-top ml-3 mb-2 d-flex justify-content-center" style="max-width: 100% !important;"><img class="radio-qr-img" src="{{campaignImg}}" alt=""></div>
                </div>
                <button class="btn device-flex-bodydevice-flex-body c-btn btn-block my-2 outline" (click)="generateQrCode()">{{ "Download" | translate}}</button>
            </div>
        </div>
    </div>
</div>

<!-- select logo start -->

<div class="modal fade" id="selectLogoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center border-bottom-light">
                <h5 class="modal-title" id="exampleModalLabel">{{ "Select Logo" | translate}}</h5>
                <button type="button" class="close t-color" data-dismiss="modal" id="closePrepareProfile" aria-label="Close" (click)="selectLogo();">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4 device-flex-body">
                <div class="small text-danger" *ngIf="logoSelectErr">{{"Please select logo" | translate}}</div>
                <div *ngIf="logosArray && logosArray.length <= 0 && !allLogoSpinner">{{'No Logo Found' | translate}}!</div>
                <div class="main-logo-list" *ngIf="logosArray && logosArray.length > 0">
                    <div class="single-logo" *ngFor="let item of logosArray;let i = index;">
                        <div *ngIf="defaultLogoId" [ngClass]="{'default-icon': item._id === defaultLogoId}">
                            <i [ngClass]="{'fa fa-check': item._id === defaultLogoId}"></i>
                        </div>
                        <img src="{{'data:image/png;base64,' + item.data}}" id="single-logo-{{i}}" class="img-logo cursor-p" (keypress)="logoSelectErr = false;" (click)="logoId = item._id; logoImg = 'data:image/png;base64,' + item.data; logoSelected = logoId === item._id ? true : false;toggleBorderClass(i);" alt="">
                    </div>
                </div>
                <div class="d-flex manage-logo">
                    <a class="btn logo-btn my-3 d-block d-flex mr-3" [ngClass]="{'logo-btn': !logoSelected, 'disable-logo-btn': logoSelected }" (click)="uploadQrCodeLogo()">
                        {{ "Upload" | translate}}
                        <input id="group-qr-code-logo" type="file" style="display: none" accept=".png,.jpg" (change)="onQrLogoChange($event)">
                        <svg class="ml-2 svg-t-size cursor-p" xmlns="http://www.w3.org/2000/svg" width="13.26" height="22.595" viewBox="0 0 19.26 22.595"><defs><style>.a{fill:#1dbc8e;}</style></defs><g transform="translate(-37.781)"><g transform="translate(46.692)"><g transform="translate(0)"><path class="a" d="M240.577,0a.862.862,0,0,0-.862.862V14.546a.862.862,0,0,0,1.725,0V.862A.862.862,0,0,0,240.577,0Z" transform="translate(-239.715 0)"/></g></g><g transform="translate(41.92 0)"><path class="a" d="M142.577,4.688,137.805.232a.863.863,0,0,0-1.177,0l-4.772,4.456a.862.862,0,1,0,1.177,1.261l4.183-3.906L141.4,5.949a.862.862,0,0,0,1.177-1.261Z" transform="translate(-131.582 0)"/></g><g transform="translate(37.781 7.359)"><g transform="translate(0)"><path class="a" d="M56.179,166.758h-4.6a.862.862,0,1,0,0,1.725h3.737v11.786H39.506V168.483h3.737a.862.862,0,0,0,0-1.725h-4.6a.862.862,0,0,0-.862.862v13.511a.862.862,0,0,0,.862.862H56.179a.862.862,0,0,0,.862-.862V167.62A.862.862,0,0,0,56.179,166.758Z" transform="translate(-37.781 -166.758)"/></g></g></g></svg>
                    </a>
                    <button class="btn logo-btn btn-block my-3 mr-3" style="width: 655px;" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="setDefaultLogo();">{{ "Set As Default" | translate}}<i class="fa fa-check ml-2"></i></button>
                    <button class="btn logo-btn btn-block my-3" [ngClass]="{'logo-btn': logoSelected, 'disable-logo-btn': !logoSelected }" (click)="deleteLogo();">{{ "Delete" | translate}}<i class="fa fa-trash ml-2"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- select logo end -->


<!-- non profile type modal start -->

<div class="modal fade" id="profileTypeRequired" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width:800px;" role="document">
        <div class="modal-content t-modal">
            <div class="modal-header text-center" style="border-bottom: none;">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close t-color outline" data-dismiss="modal" id="closePrepareProfile" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body device-flex-body" style="padding: 35px;">
                <h4 class="text-center" style="line-height: 60px;">
                    {{ "Dedicated profile type is required to use this feature. Kindly contact JCIUS Support team to provision a dedicated profile type." | translate}}
                </h4>
            </div>
        </div>
    </div>
</div>

<!-- non profile type modal end -->
