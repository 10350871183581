import { Component, Injectable, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { CommunicationService } from '../../services/communication.service';
import { Store } from '@ngrx/store';
import { FilterState } from '../../stores/filter/filter.reducer';
import { ManageGroupQrFilterState } from '../../stores/manage-group-qr-filter/filter.reducer';
import * as FilterAction from '../../stores/filter/filter.action';
import * as ManageGroupQrFilterAction from '../../stores/manage-group-qr-filter/filter.action';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ThemeState } from '../../stores/theme/theme.reducer';
import * as ThemeAction from '../../stores/theme/theme.action';
import { LogoState } from '../../stores/logo/logo.reducer';
import * as LogoAction from '../../stores/logo/logo.action';
import { UiCustomizationService } from '../../services/ui-customization.service';
// import * as $ from 'jquery';
declare var $: any;
import { UserState } from '../../stores/user/user.reducer';
import { CustomerService } from '../../services/customer.service';
import { CookieTrailService } from '../../services/cookie-trail.service';
import { GroupMgmtService } from '../../services/group-mgmt.service';
import { UserService } from '../../services/user.service';
import * as UserAction from '../../stores/user/user.action';
import { DomSanitizer } from '@angular/platform-browser';
import { GroupProfileService } from '../../services/group-profile.service';
import { HierarchyState } from '../../stores/hierarchy-tree/hierarchy.reducer';
import * as HierarchyAction from '../../stores/hierarchy-tree/hierarchy.action'
import { DashboardState } from '../../stores/dynamic-dashboard-sidebar/dashboard.reducer';
import * as DashboardAction from '../../stores/dynamic-dashboard-sidebar/dashboard.action';
import { ProfileTypeComponent } from 'src/app/dynamic-dashboard/profile-type/profile-type.component';
import { TotalDownloadComponent } from 'src/app/dynamic-dashboard/total-download/total-download.component';
import { DownloadTrendGraphComponent } from 'src/app/dynamic-dashboard/download-trend-graph/download-trend-graph.component';
import { DownloadForecastGraphComponent } from 'src/app/dynamic-dashboard/download-forecast-graph/download-forecast-graph.component';
import { DeviceAnalyticsComponent } from 'src/app/dynamic-dashboard/device-analytics/device-analytics.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  profileTypeList;
  manageGroupList;
  filterArray = [];
  manageGroupQrFilterArray = [];
  currentUrl;
  apiList = [];
  originalApiList;
  serchTerm;

  sideBarColor;
  backgroundColor;
  fontColor;
  buttonColor;

  lightLargeFilePath;
  darkLargeFilePath;
  lightSmallFilePath;
  darkSmallFilePath;
  routePermission;
  customerList;
  customerName;
  selectedCustomer;
  switchCustomer;
  btnSpinner = false;
  rootCustomer;
  currentCustomer;
  reportFeatures;
  apiFeatures;
  blacklistFeatures;
  features;
  groupList;
  customerMgmtFlg = false;
  isProd;
  showProfileInventory = false;
  groupProfileTypeList = [];
  selectedTree;
  showDynamicDashboardSidebar = false;
  defaultSidebar = []
  sidebarWidgetList = [];
  serchWidget;
  screenMode='dark';
  component;
  customerAction = false;
  customerSelectionList;
  customersTreeSubscription: Subscription;
  customerLevelSubscription: Subscription;
  titleClick = false;
  isParent;
  language;
  isDashboardUser=false;

  constructor(
    private profileService: ProfileService,
    private communicationService: CommunicationService,
    private filterStore: Store<FilterState>,
    private manageGroupQrfilterStore: Store<ManageGroupQrFilterState>,
    private router: Router,
    private apiService: ApiService,
    private themeService: UiCustomizationService,
    private themeStore: Store<ThemeState>,
    private userStore: Store<UserState>,
    private logoStore: Store<LogoState>,
    private customerService: CustomerService,
    private cookieTrailService: CookieTrailService,
    private groupService: GroupMgmtService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private grpProfileService: GroupProfileService,
    private hierarchyStore: Store<HierarchyState>,
    private dashboardStore: Store<DashboardState>,
    private translate: TranslateService,
    ) { }

  ngOnInit() {
    this.currentUrl = window.location.pathname;

    this.isProd = localStorage.getItem('isProd') === 'true' ? true : false;

    if (!localStorage.getItem('screenMode')) {
      $('#screenMode')[0].checked = true;
      localStorage.setItem('screenMode', 'light');
      this.screenMode = localStorage.getItem('screenMode');
      $('#mainContainer').removeClass('dark-content');
      $('#mainContainer').addClass('light-content');
    } else if (localStorage.getItem('screenMode') === 'dark') {
      $('#screenMode')[0].checked = false;
      localStorage.setItem('screenMode', 'dark');
      this.screenMode = localStorage.getItem('screenMode');
      $('#mainContainer').removeClass('light-content');
      $('#mainContainer').addClass('dark-content');
    } else if (localStorage.getItem('screenMode') === 'light') {
      $('#screenMode')[0].checked = true;
      localStorage.setItem('screenMode', 'light');
      this.screenMode = localStorage.getItem('screenMode');
      $('#mainContainer').addClass('light-content');
      $('#mainContainer').removeClass('dark-content');
    }

    this.getTheme();
    this.getLogos();

    this.userStore.select('user')
      .subscribe((res: any) => {
        if (res) {
          this.routePermission = res.permissions;
          if (this.routePermission && (this.routePermission.indexOf('api') !== -1)) {
            this.getApiList();
          }
          this.features = res.featureRole;

          if(res.featureRole && res.featureRole.length > 0) {
            this.reportFeatures = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'Reports')].features;
            this.apiFeatures = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'API')].features;
            this.blacklistFeatures = res.featureRole[res.featureRole.findIndex(ele => ele.name === 'Manage Blacklist')].features;
          }

          if (!res.data.currentCustomer && (res.data.rootCustomer && res.data.rootCustomer.isParent)) {
            this.isParent = true;
          } else if (res.data.currentCustomer && res.data.currentCustomer.isParent){
            this.isParent = true;
          } else {
            this.isParent = false;
          }

          this.allowManageGroup();
          this.rootCustomer = res.data.rootCustomer;
          this.currentCustomer = res.data.currentCustomer;
          localStorage.setItem('rootCustomer', JSON.stringify(res.data.rootCustomer));

          if (res.data.currentCustomer) {
            localStorage.setItem('currentCustomer', JSON.stringify(res.data.currentCustomer));
            this.selectedCustomer = res.data.currentCustomer._id;
            this.communicationService.sendCustomer(this.isProd ? res.data.currentCustomer.prodName : res.data.currentCustomer.name);
          } else {
            localStorage.removeItem('currentCustomer');
            // localStorage.setItem('currentCustomer', JSON.stringify(res.data.rootCustomer));
            this.selectedCustomer = res.data.rootCustomer._id;
            this.communicationService.sendCustomer(this.isProd ? res.data.rootCustomer.prodName : res.data.rootCustomer.name);
          }

          if (res.data.roles[0] === 'admin' && !this.customerMgmtFlg) {
            this.getCustomerSelectionList();
          }

          if(res.data.roleNames.includes('dashboard-user') && res.data.roleType === 'default') {
            this.isDashboardUser = true;
          }
        }
      });

    this.communicationService.getScreenMode()
      .subscribe((res: any) => {
        if (res === 'dark') {
          $('#screenMode')[0].checked = false;
          localStorage.setItem('screenMode', 'dark');
          this.screenMode = localStorage.getItem('screenMode');
        } else if (res === 'light') {
          $('#screenMode')[0].checked = true;
          localStorage.setItem('screenMode', 'light');
          this.screenMode = localStorage.getItem('screenMode');
        }
      });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (window.location.pathname) {
          this.currentUrl = window.location.pathname;
        } else {
          this.currentUrl = val.url;
        }
      }
    });

    this.communicationService.getLogo()
      .subscribe((res: any) => {
        this.getLogos();
      });
    this.communicationService.getLanguage().
      subscribe((res: any) => {
        this.changeLanguage();
    });

    this.communicationService.getPreviewClick()
      .subscribe((res: any) => {
        if (res.screenMode === 'dark') {
          const fr1 = new FileReader();
          const fr2 = new FileReader();

          if (res.file1) {
            fr1.onload = () => {
              document.getElementById('dark-large-app-icon').setAttribute('src', fr1.result.toString());
            };
            fr1.readAsDataURL(res.file1);
          }

          if (res.file2) {
            fr2.onload = () => {
              document.getElementById('dark-small-app-icon').setAttribute('src', fr2.result.toString());
            };
            fr2.readAsDataURL(res.file2);
          }
        } else if (res.screenMode === 'light') {
          const fr1 = new FileReader();
          const fr2 = new FileReader();

          if (res.file1) {
            fr1.onload = () => {
              document.getElementById('light-large-app-icon').setAttribute('src', fr1.result.toString());
            };
            fr1.readAsDataURL(res.file1);
          }

          if (res.file2) {
            fr2.onload = () => {
              document.getElementById('light-small-app-icon').setAttribute('src', fr2.result.toString());
            };
            fr2.readAsDataURL(res.file2);
          }
        }
      });

      this.getAllGroups();

      this.communicationService.getCustomerList()
        .subscribe(res => {
          this.customerAction = true;
          this.getCustomers();
        });

    this.getCustomers();

    
    this.hierarchyStore.select('hierarchy').subscribe((data: any) => {     
      setTimeout(() => {
        this.customerAction = false;
        if(this.component != 'sidebar') {
          this.selectedTree = data;
            if(this.customerList && this.customerList.length > 0 && this.selectedTree.length > 0 && !this.selectedTree.includes(this.rootCustomer._id)) {
              this.customerList.forEach(customer => {
                if (this.selectedTree.includes(customer._id)) {
                  customer.display = true;
                } else {
                  customer.display = false;
                }
              });
            }
            if(this.selectedTree && this.selectedTree.length > 0) {
              if(!this.titleClick) {
                $(`#customerMgmtCollapse`).addClass('show');
              }

              this.selectedTree.forEach((element, ind) => {
                $(`#customerMgmt_${element}`).addClass('show');
                $(`#customerMgmtArrow_${element}`).removeClass('collapsed');
              });
            }
        }
        this.component = '';
      }, 100);
    })
    this.dashboardStore.select('dashboard').subscribe((data) => {
      if(Object.keys(data).includes('show')) {
        this.showDynamicDashboardSidebar = data['show'];
      }
      if(Object.keys(data).includes('allWidgets')) {
        this.defaultSidebar = data['allWidgets'];
        this.sidebarWidgetList = data['allWidgets'];
      }
    })

    this.filterStore.select("filter").subscribe((res: any) => {
      let selectedIndex;
      if (res?.profileType && res?.type === "profile") {
        let str = res.type;
        res = { ...res, str };
        let samplePromise;
        if (!res.groupQR && !$("#sidebarCollapseOne").hasClass("show")) {
          $("#manage-profile-btn").click();
          samplePromise = new Promise((resolve, reject) => {
            this.profileService.getProfileTypeList().subscribe(resolve, reject);
          });
        }

        if (res.groupQR && !$("#groupProfileId").hasClass("show")) {
          $("#manage-groupqr-btn").click();
          $("#manage-groupqr-profile-inventory-btn").click();
        }

        samplePromise = new Promise((resolve, reject) => {
          if (!res.groupQR) {
            this.profileService.getProfileTypeList().subscribe(resolve, reject);
          } else {
            this.grpProfileService
              .getGroupProfileTypeList()
              .subscribe(resolve, reject);
          }
        });

        samplePromise.then((data) => {
          if (!res.groupQR) {
            this.profileTypeList = data.profileTypes;
            this.filterArray = [];
            selectedIndex = this.profileTypeList.findIndex(
              (ele) => ele.name === res.profileType,
            );
            this.profileTypeList[selectedIndex].checked = true;
            this.profileTypeList.forEach((ele) => {
              if (ele.checked && this.filterArray.indexOf(ele.name) === -1) {
                this.filterArray.push(ele.name);
              }
            });
            this.filterStore.dispatch(
              new FilterAction.GetFilter(Object.assign([], this.filterArray)),
            );
          } else {
            this.groupProfileTypeList = data.profileTypes;
            if (localStorage.getItem("manageGroupQrfilteTerms")) {
              const temp1 = JSON.parse(
                localStorage.getItem("manageGroupQrfilteTerms"),
              );
              selectedIndex = this.groupProfileTypeList.findIndex(
                (ele) => ele.name === res.profileType,
              );
              this.groupProfileTypeList[selectedIndex].groupQrCheck = true;
              this.groupProfileTypeList.forEach((ele, index) => {
                ele.id = index;
                if (
                  ele.groupQrCheck &&
                  temp1[0].profileTypesFilter.indexOf(ele.name) !== -1
                ) {
                  this.manageGroupQrFilterArray.push(ele.name);
                }
              });
              this.filterStore.dispatch(
                new FilterAction.GetFilter(
                  Object.assign([], this.manageGroupQrFilterArray),
                ),
              );
            }
          }
        });
      } else if (res) {
        // trigger collapse nav
        if (!$("#sidebarCollapseOne").hasClass("show")) {
          $("#manage-profile-btn").click();
        }
        let result = res;
        this.groupService.getCustomerUserGroups().subscribe(
          (res: any) => {
            this.manageGroupList = res;
            if (localStorage.getItem("filteTerms")) {
              const temp = JSON.parse(localStorage.getItem("filteTerms"));
              this.manageGroupList.forEach((ele, index) => {
                ele.id = index;
                if (temp[0].profileTypesFilter.indexOf(ele._id) !== -1) {
                  this.filterArray.push(ele._id);
                  ele.checked = true;
                } else {
                  ele.checked = false;
                }
              });
              let selectedIndex = this.manageGroupList.findIndex(
                (ele: any) => ele._id === result["groupId"]?.toString(),
              );
              if (~selectedIndex) {
                this.groupFilter(selectedIndex);
              }
            }
          },
          (err) => {
            this.communicationService.openAlert({
              alertType: "error",
              alertMsg: err.error.message || "Something went wrong!",
            });
          },
        );
      }
    });
  }

  hideDynamicSidebarHandler() {
    this.dashboardStore.dispatch(new DashboardAction.GetSidebar({ show: false }));
  }

  allowManageGroup() {
    this.grpProfileService.allowManageGroup()
    .subscribe((res: any) => {
      if(res) {
        this.showProfileInventory = true;
      } else {
        this.showProfileInventory = false;
      }
    }, err => {
      this.showProfileInventory = false;
      this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message});
    });
  }

  changeLanguage(){
    this.language = localStorage.getItem('language');
    this.translate.use(this.language);
  }

  getMainFeatureIndex(name) {
    return this.features ? this.features.findIndex(ele => ele.name === name) : '';
  }

  getFeatureIndex(keyName, featureName) {
    if(featureName === 'report') {
      return this.reportFeatures ? this.reportFeatures.findIndex(ele => ele.key === keyName) : '';
    } else if(featureName === 'api') {
      return this.apiFeatures ? this.apiFeatures.findIndex(ele => ele.key === keyName) : '';
    } else if(featureName === 'blacklist') {
      return this.blacklistFeatures ? this.blacklistFeatures.findIndex(ele => ele.key === keyName) : '';
    }
  }

  getCustomerSelectionList() {
    this.customerService.getUserCustomers()
    .subscribe((res: any) => {
      this.customerSelectionList = res.customerList;
      // setTimeout(() => {
      //   if(this.selectedTree && this.selectedTree.length > 0) {
      //     this.selectedTree.forEach((element, ind) => {
      //       $(`#customerMgmt_${element}`).addClass('show');
      //       $(`#customerMgmtArrow_${element}`).toggleClass('collapsed');
      //     });
      //   }
      // }, 500);
    }, err => {
      const cookieTrail = {
        screenName: 'Sidebar',
        action: 'Get Customers',
        error: err.error || 'Something went wrong!',
      };
    
      this.cookieTrailService.saveCookie(cookieTrail);
      this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
    });
  }

  getCustomers() {
    let customerId;
    // if(!this.customerList || this.customerList.length === 0 || this.customerAction) {
      if(this.customersTreeSubscription) {
        this.customersTreeSubscription.unsubscribe();
      }
      setTimeout(() => {
        this.customersTreeSubscription = this.customerService.getUserCustomers()
        .subscribe((res: any) => {
          if (!this.titleClick) { 
            this.customerList = res.customerList;
          }
          
          setTimeout(() => {
            if(this.component != 'sidebar') {
              if(this.selectedTree && this.selectedTree.length > 0) {

                if((this.selectedTree.length > 0 && !this.selectedTree.includes(this.rootCustomer._id))) {
                  this.customerList.forEach(customer => {
                    if (this.selectedTree.includes(customer._id)) {
                      customer.display = true;
                    } else {
                      customer.display = false;
                    }
                  });
                } else {
                  this.customerList.forEach(element => {
                    element.display = true;
                  });
                }

                if (!this.titleClick) {
                  $(`#customerMgmtCollapse`).addClass('show');
                }
                this.titleClick = false;

                this.selectedTree.forEach((element, ind) => {
                  
                  $(`#customerMgmt_${element}`).addClass('show');
                  $(`#customerMgmtArrow_${element}`).toggleClass('collapsed');
                });
              }
            } else {
              this.getCustomerLevel();
            }
          }, 100);
        }, err => {
          const cookieTrail = {
            screenName: 'Sidebar',
            action: 'Get Customers',
            error: err.error || 'Something went wrong!',
          };
        
          this.cookieTrailService.saveCookie(cookieTrail);
          this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
        });
      }, 100);
    
    // }
  }

  getCustomerLevel() {
    if(this.customerLevelSubscription) {
      this.customerLevelSubscription.unsubscribe();
    }
    this.customerLevelSubscription = this.customerService.customerLevel()
    .subscribe((res: any) => {
      this.selectedTree = res.parentCustomer;
      if(this.selectedTree && this.selectedTree.length > 0) {
        if((this.selectedTree.length > 0 && !this.selectedTree.includes(this.rootCustomer._id))) {
          this.customerList.forEach(customer => {
            if (this.selectedTree.includes(customer._id)) {
              customer.display = true;
            } else {
              customer.display = false;
            }
          });
        }

        this.selectedTree.forEach((element, ind) => {
          $(`#customerMgmtCollapse`).addClass('show');
          $(`#customerMgmt_${element}`).addClass('show');
          $(`#customerMgmtArrow_${element}`).toggleClass('collapsed');
        });
      }
    }, (err) => {

    });
  }

  confirmCustomerSelection(customer) {
    this.customerName = customer === 'root' ? this.rootCustomer.name : customer.name;
    this.switchCustomer = customer === 'root' ? this.rootCustomer : customer;
    $('#customerSelectionConfirmation').modal({ backdrop: 'static', keyboard: false });
  }

  cancelSwitch() {
    this.selectedCustomer = this.currentCustomer ? this.currentCustomer._id : '';
    this.switchCustomer = '';
  }

  switchCustomerSelection() {
    this.btnSpinner = true;
    const cookieTrail = {
      screenName: 'Sidebar',
      action: 'Switch Customer',
      body: {customerId: this.switchCustomer._id},
    };
    this.cookieTrailService.saveCookie(cookieTrail);
    this.customerService.selectCustomer(this.switchCustomer._id)
      .subscribe((res: any) => {
        $('#customerSelectionConfirmation').modal('hide');
        this.btnSpinner = false;
        this.switchCustomer = '';
        localStorage.removeItem('filteTerms');
        window.location.href = this.features[this.getMainFeatureIndex('Dashboard')].display ? '/dashboard' : '/dashboard-user';
      }, err => {
        const cookieTrail = {
          screenName: 'Sidebar',
          action: 'Switch Customer',
          body: {customerId: this.switchCustomer._id},
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.btnSpinner = false;
        this.switchCustomer = '';
        this.selectedCustomer = this.currentCustomer ? this.currentCustomer._id : '';
        $('#customerSelectionConfirmation').modal('hide');
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  resetCustomer() {
    this.btnSpinner = true;
    const cookieTrail = {
      screenName: 'Sidebar',
      action: 'Reset Customer',
    };
    
    this.cookieTrailService.saveCookie(cookieTrail);
    this.customerService.selectCustomer(this.rootCustomer._id)
      .subscribe((res: any) => {
        this.btnSpinner = false;
        $('#customerSelectionConfirmation').modal('hide');
        this.switchCustomer = '';
        localStorage.removeItem('filteTerms');
        window.location.href = this.features[this.getMainFeatureIndex('Dashboard')].display ? '/dashboard' : '/dashboard-user';
      }, err => {
        const cookieTrail = {
          screenName: 'Sidebar',
          action: 'Reset Customer',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        $('#customerSelectionConfirmation').modal('hide');
        this.switchCustomer = '';
        this.btnSpinner = false;
        this.selectedCustomer = this.currentCustomer._id;
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getLogos() {
    this.themeService.getLogos()
      .subscribe((res: any) => {
        this.logoStore.dispatch(new LogoAction.GetLogo(res));
        if (res && !res.message && res.find(ele => (ele.mode === 'dark' && ele.type === 'large'))) {
          this.darkLargeFilePath = 'data:image/png;base64,' + res.find(ele => (ele.mode === 'dark' && ele.type === 'large')).data;
        } else {
          document.getElementById('dark-large-app-icon').setAttribute('src', 'assets/images/logo/large-dark-logo-new.png');
          this.darkLargeFilePath = 'assets/images/logo/large-dark-logo-new.png';
        }

        if (res && !res.message && res.find(ele => (ele.mode === 'dark' && ele.type === 'small'))) {
          this.darkSmallFilePath = 'data:image/png;base64,' + res.find(ele => (ele.mode === 'dark' && ele.type === 'small')).data;
        } else {
          document.getElementById('dark-small-app-icon').setAttribute('src', 'assets/images/logo/small-dark-logo-new.png');
          this.darkSmallFilePath = 'assets/images/logo/small-dark-logo-new.png';
        }

        if (res && !res.message && res.find(ele => (ele.mode === 'light' && ele.type === 'large'))) {
          this.lightLargeFilePath = 'data:image/png;base64,' + res.find(ele => (ele.mode === 'light' && ele.type === 'large')).data;
        } else {
          document.getElementById('light-large-app-icon').setAttribute('src', 'assets/images/logo/10T-large-logo.png');
          this.lightLargeFilePath = 'assets/images/logo/10T-large-logo.png';
        }

        if (res && !res.message && res.find(ele => (ele.mode === 'light' && ele.type === 'small'))) {
          this.lightSmallFilePath = 'data:image/png;base64,' + res.find(ele => (ele.mode === 'light' && ele.type === 'small')).data;
        } else {
          document.getElementById('light-small-app-icon').setAttribute('src', 'assets/images/logo/small-light-logo-new.png');
          this.lightSmallFilePath = 'assets/images/logo/small-light-logo-new.png';
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Sidebar',
          action: 'Get Logos',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message });
      });
  }

  changeScreenMode() {
    if ($('#screenMode')[0].checked) {
      localStorage.setItem('screenMode', 'light');
      this.screenMode = localStorage.getItem('screenMode');
      $('#mainContainer').addClass('light-content');
      $('#mainContainer').removeClass('dark-content');
    } else {
      localStorage.setItem('screenMode', 'dark');
      this.screenMode = localStorage.getItem('screenMode');
      $('#mainContainer').removeClass('light-content');
      $('#mainContainer').addClass('dark-content');
    }
  }

  openSidebar() {
    $('#sidebar-toggle').removeClass('toggle-sidebar-on');
    $('#sidebar-toggle').addClass('toggle-sidebar-on-powered');
  }

  getProfileGroup() {
    if(this.currentCustomer && this.currentCustomer.isParent) {
      this.getProfileTypes();
    } else if(this.currentCustomer && !this.currentCustomer.isParent) {
      this.getManageProfileUser();
    } else if(this.rootCustomer && this.rootCustomer.isParent){
      this.getProfileTypes();
    } else if(this.rootCustomer && !this.rootCustomer.isParent) {
      this.getManageProfileUser();
    }
  }

  getGroupProfileTypeList() {
    this.manageGroupQrFilterArray = [];
    this.grpProfileService.getGroupProfileTypeList()
      .subscribe((res: any) => {
        this.groupProfileTypeList = res.profileTypes;
        if (localStorage.getItem('manageGroupQrfilteTerms')) {
          const temp1 = JSON.parse(localStorage.getItem('manageGroupQrfilteTerms'));
          this.groupProfileTypeList.forEach((ele, index) => {
            ele.id = index;
            if (temp1[0].profileTypesFilter.indexOf(ele.name) !== -1) {
              this.manageGroupQrFilterArray.push(ele.name);
              ele.groupQrCheck = true;
            } else {
              ele.groupQrCheck = false;
            }
          });
        }
      });
  }

  getProfileTypes() {
    this.filterArray = [];
    this.profileService.getProfileTypeList()
      .subscribe((res: any) => {
        this.profileTypeList = res.profileTypes;
        if (localStorage.getItem('filteTerms')) {
          const temp = JSON.parse(localStorage.getItem('filteTerms'));
          this.profileTypeList.forEach((ele, index) => {
            ele.id = index;
            if (temp[0].profileTypesFilter.indexOf(ele.name) !== -1) {
              this.filterArray.push(ele.name);
              ele.checked = true;
            } else {
              ele.checked = false;
            }
          });
        }

      }, err => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  getManageProfileUser() {
    this.filterArray = [];
    if(this.rootCustomer && this.rootCustomer.isRoot) {
      this.groupService.getCustomerUserGroups()
        .subscribe((res: any) => {
          this.manageGroupList = res;
          if (localStorage.getItem('filteTerms')) {
            const temp = JSON.parse(localStorage.getItem('filteTerms'));
            this.manageGroupList.forEach((ele, index) => {
              ele.id = index;
              if (temp[0].profileTypesFilter.indexOf(ele._id) !== -1) {
                this.filterArray.push(ele._id);
                ele.checked = true;
              } else {
                ele.checked = false;
              }
            });
          }
        }, err => {
          this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
        });
    } else {
      this.userService.getUser()
      .subscribe((user: any) => {
        this.manageGroupList = user.data.groups;
        if (localStorage.getItem('filteTerms')) {
          const temp = JSON.parse(localStorage.getItem('filteTerms'));
          this.manageGroupList.forEach((ele, index) => {
            ele.id = index;
            if (temp[0].profileTypesFilter.indexOf(ele._id) !== -1) {
              this.filterArray.push(ele._id);
              ele.checked = true;
            } else {
              ele.checked = false;
            }
          });
        }
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'});
      });
    }
    
  }

  groupFilter(index) {
    this.manageGroupList[index].checked = !this.manageGroupList[index].checked;
    setTimeout(() => {
      this.manageGroupList.forEach(ele => {
        if (ele.checked && (this.filterArray.indexOf(ele._id) === -1)) {
          this.filterArray.push(ele._id);
          setTimeout(() => {
            this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], this.filterArray)));
          }, 10);
        } else if (!ele.checked && (this.filterArray.indexOf(ele._id) !== -1)) {
          this.filterArray = this.filterArray.filter(i => i !== ele._id);
          setTimeout(() => {
            this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], this.filterArray)));
          }, 10);
        }
      });
    }, 10);
  }

  profileTypeFilter(index, type) {
    this.profileTypeList[index].checked = !this.profileTypeList[index].checked;
    setTimeout(() => {
      this.profileTypeList.forEach(ele => {
        if (ele.checked && (this.filterArray.indexOf(ele.name) === -1)) {
          this.filterArray.push(ele.name);
          setTimeout(() => {
            this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], this.filterArray)));
          }, 10);
        } else if (!ele.checked && (this.filterArray.indexOf(ele.name) !== -1)) {
          this.filterArray.splice(this.filterArray.indexOf(ele.name), 1);
          if(!type) {
            setTimeout(() => {
              this.filterStore.dispatch(new FilterAction.GetFilter(Object.assign([], this.filterArray)));
            }, 10);
          }
        }
      });
    }, 10);
  }

  manageGroupQrprofileTypeFilter(index) {
    this.groupProfileTypeList[index].groupQrCheck = !this.groupProfileTypeList[index].groupQrCheck;

    setTimeout(() => {
      this.groupProfileTypeList.forEach(ele => {
        if (ele.groupQrCheck && (this.manageGroupQrFilterArray.indexOf(ele.name) === -1)) {
          this.manageGroupQrFilterArray.push(ele.name);
          setTimeout(() => {
            this.manageGroupQrfilterStore.dispatch(new ManageGroupQrFilterAction.GetManageGroupQrFilter(Object.assign([], this.manageGroupQrFilterArray)));
          }, 10);
        } else if (!ele.groupQrCheck && (this.manageGroupQrFilterArray.indexOf(ele.name) !== -1)) {
          this.manageGroupQrFilterArray.splice(this.manageGroupQrFilterArray.indexOf(ele.name), 1);
          setTimeout(() => {
            this.manageGroupQrfilterStore.dispatch(new ManageGroupQrFilterAction.GetManageGroupQrFilter(Object.assign([], this.manageGroupQrFilterArray)));
          }, 10);
        }
      });
    }, 10);
  }

  getApiList() {
    this.apiService.getApiList()
      .subscribe((res: any) => {
        this.originalApiList = res;

        this.originalApiList.forEach((element, index) => {
          element.name = element.apiNo + ' ' + element.apiName;
          
          if(element.select) {
            element.htmlSvg = this.sanitizer.bypassSecurityTrustHtml(element.svg.replace('<svg', `<svg class="active svg-t-size" id="api_icon_${index}"`));
          } else {
            element.htmlSvg = this.sanitizer.bypassSecurityTrustHtml(element.svg.replace('<svg', `<svg class="svg-t-color svg-t-size" id="api_icon_${index}"`));
          }

          this.apiList.push(element);
        });
      }, err => {
        const cookieTrail = {
          screenName: 'Sidebar',
          action: 'Get API List',
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  search() {
    this.apiList = [];
    this.originalApiList.forEach((ele: any) => {
      if (ele.apiName.toLowerCase().includes(this.serchTerm.toLowerCase())) {
        this.apiList.push(ele);
      }
    });
  }

  isEnter(evt) {

    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode === 13 && !evt.shiftKey) {
      this.apiList = [];
      this.originalApiList.forEach((ele: any) => {
        if (ele.apiName.toLowerCase().includes(this.serchTerm.toLowerCase())) {
          this.apiList.push(ele);
        }
      });
    }
  }

  addClass(evt) {
    evt.preventDefault();
    $(evt.target).addClass('active');
  }

  addApiToDashboard(id, i) {
    this.apiService.addApiToDashboard(id)
      .subscribe((res: any) => {
        if (res.select) {
          this.apiList[i].select = true;
          $('#api_icon_' + i).addClass('active');
          $('#api_icon_' + i).removeClass('svg-t-color');
          this.communicationService.sendApiStatusToDashboard({ status: true, id, name: this.apiList[i].apiName, light: this.apiList[i].light });
        } else {
          this.apiList[i].select = false;
          $('#api_icon_' + i).removeClass('active');
          $('#api_icon_' + i).addClass('svg-t-color');
          this.communicationService.sendApiStatusToDashboard({ status: false, id });
        }
      }, error => {
        this.communicationService.openAlert({ alertType: 'error', alertMsg: error.error.message || 'Something went wrong!' });
      });
  }

  getTheme() {
    this.getLogos();
    this.communicationService.sendScreenMode(localStorage.getItem('screenMode'));
    this.themeService.getTheme(localStorage.getItem('screenMode'))
      .subscribe((res: any) => {

        if (res.message === 'Theme not found!') {

          let themeData;

          if (localStorage.getItem('screenMode') === 'dark') {
            this.sideBarColor = '#000000';
            this.backgroundColor = '#1A1A1A';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
            themeData = {
              mode: localStorage.getItem('screenMode'),
              sidebar: this.sideBarColor,
              background: this.backgroundColor,
              font: this.fontColor,
              button: this.buttonColor
            };
            document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
            document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
            document.documentElement.style.setProperty('--t-font-color', this.fontColor);
            document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);
            this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
          } else if (localStorage.getItem('screenMode') === 'light') {
            this.sideBarColor = '#F0F0F0';
            this.backgroundColor = '#FFFFFF';
            this.fontColor = '#FF9016';
            this.buttonColor = '#FF9016';
            themeData = {
              mode: localStorage.getItem('screenMode'),
              sidebar: this.sideBarColor,
              background: this.backgroundColor,
              font: this.fontColor,
              button: this.buttonColor
            };
            document.documentElement.style.setProperty('--bg-sidebar', this.sideBarColor);
            document.documentElement.style.setProperty('--bg-inner-app', this.backgroundColor);
            document.documentElement.style.setProperty('--t-font-color', this.fontColor);
            document.documentElement.style.setProperty('--t-btn-color', this.buttonColor);
            this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
          }
        } else {
          document.documentElement.style.setProperty('--bg-sidebar', res.sidebar);
          document.documentElement.style.setProperty('--bg-inner-app', res.background);
          document.documentElement.style.setProperty('--t-font-color', res.font);
          document.documentElement.style.setProperty('--t-btn-color', res.button);

          const themeData = {
            mode: localStorage.getItem('screenMode'),
            sidebar: res.sidebar,
            background: res.background,
            font: res.font,
            button: res.button
          };
          this.themeStore.dispatch(new ThemeAction.GetTheme(themeData));
        }
      }, err => {
        const cookieTrail = {
          screenName: 'Sidebar',
          action: 'Get Theme',
          body: {screenMode: localStorage.getItem('screenMode')},
          error: err.error || 'Something went wrong!',
        };
        
        this.cookieTrailService.saveCookie(cookieTrail);
        this.communicationService.openAlert({ alertType: 'error', alertMsg: err.error.message || 'Something went wrong!' });
      });
  }

  openCampignEditor() {
    window.location.href = 'campaign-editor';
  }

  getAllGroups() {
    this.groupService.getAllGroups()
      .subscribe((res: any) => {
        this.groupList = res;
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  gotoCustomer(id, path, component) {
    this.component = component;
    if(component === 'customer' && this.customerList && this.customerList.length > 0) {
      this.customerList.forEach(element => {
        element.display = true;
      });
    }

    this.customerService.selectCustomer(id)
      .subscribe((res: any) => {
        // this.getCustomers();
        this.customerMgmtFlg = true;
        localStorage.removeItem('filteTerms');
        this.getUser(id, path);
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  getUser(id, path) {
    this.userService.getUser()
      .subscribe((res: any) => {
        this.userStore.dispatch(new UserAction.GetUser(res));
        this.rootCustomer = res.data.rootCustomer;
        this.currentCustomer = res.data.currentCustomer;
        localStorage.setItem('rootCustomer', JSON.stringify(res.data.rootCustomer));

        if (res.data.currentCustomer) {
          localStorage.setItem('currentCustomer', JSON.stringify(res.data.currentCustomer));
        }

        if (this.rootCustomer._id == id && window.location.href.includes('/customer')) {
          this.router.navigate(['/customer']);
        } else {
          if(path) {
            this.router.navigate(path);
          }
        }
        if(this.component === 'sidebar') {
          this.getCustomerLevel();
        }
        this.getProfileGroup();
        this.getTheme();
        this.communicationService.sendRemovedCustomer();
        // this.allowManageGroup();
      }, err => {
        this.communicationService.openAlert({alertType: 'error', alertMsg: err.error.message || 'Something went wrong!'})
      });
  }

  clearHierarchy() {
    this.hierarchyStore.dispatch(new HierarchyAction.ClearHierarchy([]));

    if(this.customerList && this.customerList.length > 0) {
      this.customerList.forEach((element, ind) => {
        $(`#customerMgmt_${element._id}`).removeClass('show');
        $(`#customerMgmtArrow_${element._id}`).addClass('collapsed');
      });
    }
  }

  addWidgetHandler(widget) {
    this.dashboardStore.dispatch(new DashboardAction.AddWidget(widget));
  }

  removeWidgetHandler(widget) {
    this.dashboardStore.dispatch(new DashboardAction.RemoveWidget(widget));
  }

  searchWidget() {
    if(this.serchWidget) {
      this.sidebarWidgetList  = this.defaultSidebar.filter(dS => dS.name.toLowerCase().includes(this.serchWidget.toLowerCase()));
    } else {
      this.sidebarWidgetList = this.defaultSidebar;
    }
  }

  isWidgetEnter(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 13 && !evt.shiftKey) {
      this.sidebarWidgetList  = this.defaultSidebar.filter(dS => dS.name.toLowerCase().includes(this.serchWidget.toLowerCase()));
    }
  }

  setWidgets(widgetSaveType) {
    this.dashboardStore.dispatch(new DashboardAction.SaveWidgetType({saveWidgetType: widgetSaveType}));
    this.communicationService.setDashboardInventory('dashboard');
  }

  goToDashboard() {
    this.communicationService.sendRefreshDashboardAction();
  }
}
